export const ILLUSTRATIONS = {
    createAccount: {
        src: 'https://images.ctfassets.net/4s7izhcdroyy/1fvUQRAlVQBjjJ64YhyVGF/40f2dfe8ba2b2b43b991ca8061040b51/CreateAccountIllustration.png',
        altText: '',
        position: 'howItWorks',
        title: 'Create an account'
    },
    downloadApp: {
        src: 'https://images.ctfassets.net/4s7izhcdroyy/5Xf7YJ4kQoRESCuLrImi8A/caca5b68f3f993d99cd495787bb001e1/DownloadAppIllustration.png',
        altText: '',
        position: 'howItWorks',
        title: 'Download the app'
    },
    ears: {
        src: 'https://images.ctfassets.net/4s7izhcdroyy/2zelq0Xou8H3EBExlqLj3/a0a27da30eb13f60dfb30fd0a89861ca/illustration_listen.jpg',
        altText: '',
        title: 'Listen and read books'
    },
    greenDownloadApp: {
        src: 'https://images.ctfassets.net/4s7izhcdroyy/5aJKtSVPX5nSk25RQQeHsR/5985858cb2312c94437b57c77e84bc6f/Step-3_3_.png',
        altText: '',
        position: 'howItWorks',
        title: 'Download the app '
    },
    listen: {
        src: 'https://images.ctfassets.net/4s7izhcdroyy/274sNyotkFpFI6wsZh222I/5869af4989c10d2642e20c9559e225ca/ListenIllustration.png',
        altText: '',
        position: 'howItWorks',
        title: 'Listen and read books'
    },
    looking: {
        src: 'https://images.ctfassets.net/4s7izhcdroyy/1anxgOU8noy2YoO64RSAtN/c7b50c147fd0391736e680fd9cf4ee50/illustration_explore.jpg',
        altText: '',
        position: 'howItWorks',
        title: 'Find gift card'
    },
    student: {
        src: 'https://images.ctfassets.net/4s7izhcdroyy/6K8OO3KekDcYt83loQARWr/c99e5364b1054a5fea640708837016fd/Step-2_2_.png',
        altText: '',
        position: 'howItWorks',
        title: 'Activate code'
    },
    gift: {
        src: 'https://images.ctfassets.net/4s7izhcdroyy/2nMgv4B0l9vDJbJN4i5i2a/94e8f54870139fcffb1e6db467e0b2ff/gift.png',
        altText: '',
        position: 'howItWorks',
        title: 'Listen and read books'
    },
    offer: {
        src: 'https://images.ctfassets.net/4s7izhcdroyy/7cazvrakw4WBSjwXB1AtGw/be73114ca1709fbbb7d37e0732e3b7d7/offer.png',
        altText: '',
        title: 'Get offer'
    }
};
